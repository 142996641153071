import React from "react";
import "./Research.css";

function Research() {
  return (
    <>
      <h2> Problem Evolution to Super Intelligence</h2>
      <div className="research-container">
        <div className="research-mainarea">
          <h3 style={{ textAlign: "center" }}>I ❤️ simple things not easy !</h3>
          <p>
            We, Human are pretty advanced, only species who managed to develop
            Language, Technology, and complex social organization and has no competition on earth !{" "}
          </p>
          <a>Chapters</a>
          <a href="/preface">Preface</a>
          <a href="/intro">Introduction</a>
          <a href="/accident">One Accident, Invention or Discovery or what?</a>
          <a href="/problem1">
            Type 1 - Problems: Intra-group Problems - People & Governance{" "}
          </a>
          <a href="/problem2">
            Type 2 - Problems: Intra-group Problems - Internationalization
          </a>
          <a href="/problem3">
            Type 3 - Problems: Cosmological & Environmental Wonders - The
            Religion
          </a>
          <a href="/problem4">
            Type 4 - Problems: Hunam Problems - Agents of vulnerabilities, Love
            and compassion
          </a>
          <a href="/abstract">
            Abstraction in Human problem space in current state of human regime
          </a>
          <a href="/conclusion">Conclusion</a>
          <a href="/future">
            Future and Current trend of Human Engineering and Evolution
          </a>
        </div>
      </div>
    </>
  );
}

export default Research;
