import React from "react";
import "./styles.css";
function Accident() {
  return (
    <div className="page-container">
      
      <div className="research-mainarea">
        <h2>Heading</h2>
        <p>
          In software engineering and computer science, abstraction is the
          process of generalizing concrete details, such as attributes, away
          from the study of objects and systems to focus attention on details of
          greater importance
        </p>
      </div>
    </div>
  );
}

export default Accident;
